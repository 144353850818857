import React from 'react';

import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { Col, Row, Alert } from 'antd';
import Button from '@uz/unitz-components-web/Button';
import InputQuillEditor from '@uz/unitz-components-web/InputQuillEditor';
import InputText from '@uz/unitz-components-web/InputText';
import Link from '@uz/unitz-components-web/Link';
import FormProvider from '@uz/unitz-components-web/FormProvider';
import { Form, FormItem } from 'formik-antd';
import gstyles from '@vl/gstyles';
import _ from 'lodash';
import styled from 'styled-components';
import FormCreateProgramTemplateCourse from '@uz/unitz-tool-components/FormCreateProgramTemplateCourse';

const CustomInput = styled.div`
  .ant-form-item {
    margin-bottom: 8px;
  }
  textarea,
  input,
  .ant-picker,
  .ant-radio-group,
  .ant-input-affix-wrapper,
  .ant-input-number {
    border-radius: 8px;
    border-color: ${gstyles.colors.border};
    &::placeholder {
      color: ${gstyles.colors.placeHolder};
      font-size: 16px;
    }
  }
  .ant-input-number {
    overflow: hidden;
  }
  .ant-select {
    &:not(.ant-select-customize-input) {
      .ant-select-selector {
        border-radius: 8px;
        border-color: ${gstyles.colors.border};
      }
    }
  }
  .ant-radio-group {
    overflow: hidden;
    .ant-radio-button-wrapper {
      width: 50%;
      border-color: ${gstyles.colors.border};
      text-align: center;
      color: ${gstyles.colors.main};
      font-weight: 400;
      &.ant-radio-button-wrapper-checked {
        color: ${gstyles.colors.white500};
        font-weight: bold;
      }
      &:first-child {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }
      &:last-child {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }
  }
  span {
    .ant-upload {
      border-radius: 8px;
      border-color: ${gstyles.colors.border};
      background-color: ${gstyles.colors.background2};
      .ant-upload.ant-upload-btn {
        padding: 0;
      }
    }
  }
`;

const CustomEditor = styled.div`
  .text-editor {
    .ql-toolbar {
      border-radius: 8px 8px 0 0;
      border-color: ${gstyles.colors.border};
    }
    .quill {
      .ql-container {
        border-radius: 0 0 8px 8px;
        border-color: ${gstyles.colors.border};
        .ql-editor {
          &::before {
            color: ${gstyles.colors.placeHolder};
          }
        }
      }
    }
  }
`;

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
  layout: 'vertical',
};

const gutter = {
  xs: 16,
  sm: 16,
  md: 16,
  lg: 16,
};

const View8 = () => (
  <DIV className="component">
    <DIV forceCtx>
      <FormProvider form={ctx.get('create_form')}>
        <Form {...layout} className="program-template-form">
          <Row>
            <Col className="formStatus" span={24}>
              {!!ctx.get('form.status.error') && (
                <div className="py-2">
                  <Alert message={ctx.get('form.status.error.message')} type="error" />
                </div>
              )}
              {!!ctx.get('form.status.success') && (
                <div className="py-2">
                  <Alert message={ctx.get('form.status.success.message')} type="success" />
                </div>
              )}
            </Col>
          </Row>
          <div className="steps-content">
            <FormItem
              name="name"
              label={
                <span className="text-sm font-semibold text-sub">
                  {ctx.apply('i18n.t', 'ZoomToolProgramTemplate.Form.name')}
                </span>
              }
              required
            >
              <CustomInput>
                <InputText name="name" size="medium" />
              </CustomInput>
            </FormItem>
            <FormItem
              name="description"
              label={
                <span className="text-sm font-semibold text-sub">
                  {ctx.apply('i18n.t', 'ZoomToolProgramTemplate.Form.description')}
                </span>
              }
              required
            >
              <CustomEditor>
                <InputQuillEditor name="description" toolbarId="description" />
              </CustomEditor>
            </FormItem>
          </div>
          <div className="mt-4">
            <div className="text-main font-semibold text-base mb-2">
              {ctx.apply('i18n.t', 'ZoomToolProgramTemplate.Form.courses')}
            </div>
            <DIV>
              {ctx.debug(() => {
                ctx.set('form', ctx.get('create_form'));
              })}
              <FormCreateProgramTemplateCourse />
            </DIV>
          </div>
          <Row justify="end" className="mt-4 steps-action">
            <Col span={12}>
              <Row gutter={gutter}>
                <Col span={12}>
                  <Link to={ctx.apply('routeStore.toUrl', 'toolAccountProgramTemplates')}>
                    <Button name="ink" className="w-full" loading={ctx.get('form.isSubmitting')}>
                      <span id="button-text">{ctx.apply('i18n.t', 'ZoomToolCourseTemplate.Form.cancel')}</span>
                    </Button>
                  </Link>
                </Col>
                <Col span={12}>
                  <Button
                    type="primary"
                    className="w-full"
                    loading={ctx.get('create_form.isSubmitting')}
                    onClick={ctx.get('create_form.handleSubmit')}
                    disabled={!ctx.get('create_form.canSubmit')}
                  >
                    <span id="button-text">{ctx.apply('i18n.t', 'ZoomToolCourseTemplate.Form.done')}</span>
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </FormProvider>
    </DIV>
  </DIV>
);

export default displayName(View8);
